import types from './app-constants';

export default (state, action) => {
  switch (action.type) {
    default:
      return state;

    case types.SET_TAG_OBJECT:
      return {
        ...state,
        tagObject: action.tagObject,
      };

    case types.SET_PRODUCT:
      return {
        ...state,
        product: action.product,
      };

    case types.SET_JOURNEY:
      return {
        ...state,
        journey: action.journey,
      };

    case types.SET_CARE_LABEL:
      return {
        ...state,
        careLabel: action.careLabel,
      };
  }
};
