const FETCH_PRODUCT = `https://atma-memoria-staging.azurewebsites.net/api/v1.0/averydennison/uniti/product-master-data/products/`;

const getCareLabelUrl = (id) => {
  return `https://atma-epiphron-staging.azurewebsites.net/api/v1.0/averydennison/uniti/care-label/labels/${id}/documents?language=en_EN`;
};

const FETCH_JOURNEY = `https://atma-kronos-staging.azurewebsites.net/api/v1.0/averydennison/track-and-trace/events/identifiers/`;

export default {
  FETCH_PRODUCT,
  FETCH_JOURNEY,
  getCareLabelUrl,
};
