const SET_TAG_OBJECT = 'SET_TAG_OBJECT';
const SET_PRODUCT = 'SET_PRODUCT';
const SET_JOURNEY = 'SET_JOURNEY';
const SET_CARE_LABEL = 'SET_CARE_LABEL';

export default {
  SET_TAG_OBJECT,
  SET_PRODUCT,
  SET_JOURNEY,
  SET_CARE_LABEL,
};
