import React, { createContext, useReducer } from 'react';
import types from './reducers/app-constants';
import AppReducer from './reducers/app-reducer';
import services from '../services/services';
import * as mapper from './mapper';

const initialState = {
  tagObject: null,
  product: null,
  journey: null,
  careLabel: null,
};

export const GlobalContext = createContext(initialState);
export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const fetchJourney = (labelId) => {
    services.fetchJourney(
      labelId,
      (response) => {
        dispatch({
          type: types.SET_JOURNEY,
          journey: response.data,
        });
      },
      (error) => {
        console.log('Error on get journey', error);
      },
    );
  };

  const fetchProduct = async (labelId) => {
    services.fetchCareLabel(
      labelId,
      async (labelResponse) => {
        dispatch({
          type: types.SET_CARE_LABEL,
          careLabel: mapper.mapCareLabel(labelResponse.data),
        });

        services.fetchProduct(
          labelResponse.data.gtin,
          async (productResponse) => {
            dispatch({
              type: types.SET_PRODUCT,
              product: mapper.mapProduct(productResponse.data),
            });

            services.fetchJourney(
              labelId,
              async (journeyResponse) => {
                let journeyMapped = await mapper.mapJourney(
                  journeyResponse.data,
                );
                dispatch({
                  type: types.SET_JOURNEY,
                  journey: journeyMapped,
                });
              },
              (error) => {
                console.log('Error on get journey', error);
              },
            );
          },
          (error) => {
            console.log('Error on get product: ', error);
          },
        );
      },
      (error) => {
        console.log('Error on get care label', error);
      },
    );
  };

  const setTagObject = (tagObject) => {
    dispatch({
      type: types.SET_TAG_OBJECT,
      tagObject,
    });
  };
  return (
    <GlobalContext.Provider
      value={{
        tagObject: state.tagObject,
        product: state.product,
        journey: state.journey,
        careLabel: state.careLabel,
        setTagObject,
        fetchProduct,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
