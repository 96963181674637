import axios from 'axios';
import endpoints from './endpoints';

const executeService = (url, method, data) => {
  const request = {
    method,
    url,
    headers: {
      'Access-Control-Allow-Origin': 'https://sukuar.inmindsoftware.com',
      //'Content-Type': 'application/json',
    },
  };

  if (data && (method === 'POST' || method === 'PUT')) {
    request.data = data;
  }

  if (data && method === 'GET') {
    request.params = data;
  }

  return axios(request).then((response) => {
    console.log('Response in axios', response);
    return response;
  });
};

const fetchJourney = (labelId, success, error) => {
  executeService(`${endpoints.FETCH_JOURNEY + labelId}`, 'GET')
    .then(success)
    .then(error);
};

const fetchProduct = (productId, success, error) => {
  executeService(`${endpoints.FETCH_PRODUCT + productId}`, 'GET')
    .then(success)
    .catch(error);
};

const fetchCareLabel = (labelId, success, error) => {
  executeService(endpoints.getCareLabelUrl(labelId), 'GET')
    .then(success)
    .catch(error);
};

export default {
  fetchProduct,
  fetchJourney,
  fetchCareLabel,
};
