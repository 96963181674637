let geocoder;

export function init() {
  if (window.google) {
    geocoder = new window.google.maps.Geocoder();
  }
}

export async function getLocationNameFromGeoLocation(geoLocation) {
  return new Promise((resolve, reject) => {
    geocoder.geocode(
      {
        location: {
          lat: parseFloat(geoLocation.latitude),
          lng: parseFloat(geoLocation.longitude),
        },
      },
      (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            console.log(results);
            resolve(getDisplayableAddress(results[0].address_components));
          }
        }
        resolve('Unknown location');
      },
    );
  });
}

function getDisplayableAddress(components) {
  let locationName = '';
  for (let i = 0; i < components.length; i++) {
    if (
      components[i].types.includes('sublocality') ||
      components[i].types.includes('locality') ||
      components[i].types.includes('administrative_area_level_1')
    ) {
      locationName += components[i].long_name + ', ';
    }
  }

  return locationName.slice(0, locationName.length - 2);
}
