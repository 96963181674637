import React, { Suspense, lazy } from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';

const LandingPage = lazy(() => import('../landing/landing_1'));
const ARExperience = lazy(() => import('../ar-experience/ar-experience'));
const LandingPage2 = lazy(() => import('../landing/landing_2'));
const ARExperience2 = lazy(() => import('../ar-experience/ar-experience2'));
const LandingPageTest = lazy(() => import('../landing/landing_test'));
const ARExperienceTest = lazy(() => import('../ar-experience/ar-experience3'));

function App() {
  return (
    <Suspense fallback={<span>Loading...</span>}>
      <BrowserRouter>
        <Switch>
          <Route path="/product/:labelId" exact component={LandingPage} />
          <Route path="/ar-xp" exact component={ARExperience} />
          <Route path="/product-3d/:labelId" exact component={LandingPage2} />
          <Route path="/ar-xp2" exact component={ARExperience2} />
          <Route path="/product-test" exact component={LandingPageTest} />
          <Route path="/ar-xp-test" exact component={ARExperienceTest} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
