import * as geolocationParser from '../scripts/geolocation-parser';

export function mapProduct(data) {
  return {
    name: data.ArticleName,
    size: data.Size,
    color: data.Color,
    description: data.ProductDescription,
    imgUrl: data.ImageURL,
    materials: data.MaterialsDescription,
    productSpec: data.ProductSpecifications,
    sustainability: data.Sustainability,
    fairTrade: data.FairTrade,
  };
}

export function mapCareLabel(data) {
  return {
    careSymbols: data.careSymbols[0].value,
    careInstructions: data.localizedCareLabelInstruction.en_EN.careInstructions,
  };
}

export async function mapJourney(data) {
  geolocationParser.init();
  const geolocations = data.events.map((event) => {
    return event.geoLocation;
  });
  // const locations = geolocations.map(async (geolocation) => {
  //   return await geolocation;
  // });
  // console.log(locations[0]);
  let locations = [];
  for (let i = 0; i < data.events.length; i++) {
    const location = await waitForGeolocation(geolocations[i]);
    locations.push(location);
  }

  let steps = [];
  for (let i = 0; i < data.events.length; i++) {
    steps.push({
      businessStep: data.events[i].businessStep,
      location: locations[i],
      date: data.events[i].eventTime,
    });
  }

  return steps;
}

async function waitForGeolocation(geolocation) {
  return geolocationParser
    .getLocationNameFromGeoLocation(geolocation)
    .then((location) => {
      return location;
    });
}
